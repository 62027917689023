import * as React from "react";
import {
  Wrapper,
  AboutUsWrapper,
} from "./APropos.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";

const APropos = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle}>À PROPOS</h1>
      <div className={AboutUsWrapper}>
      <br />
      <p style={{fontSize:"25px"}}>GHOST GALERIE : le Post-graffiti et ses Pères fondateurs</p>
        <br />
        <br />
        <p style={{fontSize:"16px"}}>Faire connaître ou reconnaître les artistes à l’origine de l’une des dernières grandes cultures artistiques du XX° siècle, c’est toute l’ambition de Stéphane Miquel et Caroline Pozzo di Borgo installés à Marseille depuis 2012. Travaillant d’abord en étage, GHOST GALERIE Marseille -située au 2, rue de Belloi dans le 6ème arrondissement de la cité phocéenne- ouvre ses portes en 2018 avec une rétrospective de l’artiste Dondi White (le style master général) dans un hôtel particulier du centre-ville abritant en son temps, au rez-de-chaussée, un ancien local de police ! Ironie du sort, pour ces adolescents vandales qui ont créé plus qu’un mouvement : une culture.</p>
        <br />
        <p style={{fontSize:"16px"}}>Comme de grands marchands et galeristes précurseurs et visionnaires l’ont fait précédemment, GHOST GALERIE Marseille promeut l'une des plus fantastiques révolutions artistiques du siècle dernier : le Post-graffiti, un art souvent incompris. La galerie rend hommage aux grands maîtres de l’aérosol, les pionniers du graffiti new-yorkais old school et les représentants de la scène artistique de l’East Village à New-York. Un lieu unique et inspirant qui contribue chaque jour à donner à ce mouvement la visibilité qu’il mérite.</p>
        <br />
        <p style={{fontSize:"16px"}}>Dondi White, Rammellzee, Futura 2000, A-One mais aussi DAZE, Lee Quinones, Lady Pink, Kool Koor, Crash, BLADE, Phase2, Zephyr, Seen… y côtoient les artistes de la scène de l’East Village que sont Kenny Scharf, Keith Haring, Richard Hambleton, Rick Prol, John Fekner ou encore Martin Wong… Un voyage dans ce New-York si spécifique, créatif et bouillonnant. Une immersion dans cette ville à la fois muse et mante religieuse à une époque qui osait la différence. Une culture née à New-York au milieu des années 70 toujours présente au XXI° siècle dans des domaines aussi variés que la musique, la danse, les beaux-arts ou encore la mode.</p>
        <br />
        <p style={{fontSize:"16px"}}>Une galerie de second marché, en mouvement, tout comme les fondements du graffiti. Rétrospectives, group shows ...aux côtés d’oeuvres iconiques, d’autres apportent leur singularité et leur part d’imaginaire. Une expérience unique d’immersion dans ces oeuvres produites par des adolescents nés dans le Bronx ou à Brooklyn dans les années 60. Des writers vandales qui ont fait de la rue et des rames de métro leur premier atelier. Des oeuvres de grands artistes (beaucoup ont malheureusement disparu) qui ont su ériger la bombe aérosol en étendard de leur liberté. Mais aussi une rencontre avec un quartier new-yorkais, certainement le plus excitant des années 80.</p>
        <br />
        <p style={{fontSize:"16px"}}>Au milieu des années 1980, le New York Times déclarait : « l’East Village était le quartier le plus intéressant et, peut-être, le plus excitant de la ville la plus intéressante et la plus excitante du monde. Il est devenu un quartier reconnu à l'échelle nationale et internationale pour ses idées et tendances en matière de contre-culture, en tant que centre de la culture Punk rock de la Côte Est et du mouvement New Wave. Cela incluait l'émergence de galeries d’art et de diverses salles de concert, en tant que destinations légendaires et de renommée mondiale ».  C'est dans ce renouveau culturel que les communautés de la ville se rencontrent, et que le Hip-Hop, le monde de l'art et l’univers Rock et Punk se croisent. Cet environnement exceptionnel verra une scène artistique singulière où se mêlent des mondes aux contours infinis. Un quartier où les rencontres les plus improbables se sont faites et où la scène de l'East Village a introduit un panthéon de grands noms tels que Jean-Michel Basquiat, Keith Haring, Dondi White, Futura 2000, Jenny Holzer, Peter Hujar, David Wojnarowich, Patti Smith, Blondie, Madonna... .</p>
        <br />
      </div>
    </div>
  );
};

export default APropos;
